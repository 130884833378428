import { Organization, OrganizationBE } from '@/types/organization';

export const mapOrganizationBEsToDTO = (
  organizationBE: OrganizationBE
): Organization => {
  return {
    id: organizationBE.id,
    status: Boolean(organizationBE.status),
    name: organizationBE.name,
    email: organizationBE.email,
    phone: organizationBE.phone,
    address: organizationBE.address,
    contactName: organizationBE.contact_name,
    contactMail: organizationBE.contact_mail,
    contactPhone: organizationBE.contact_phone,
    billingName: organizationBE.billing_name,
    billingAddress: organizationBE.billing_address,
    taxNumber: organizationBE.tax_number,
  };
};

export const mapOrganizationsBEsToDTO = (
  organizationBEArray: OrganizationBE[]
): Organization[] => {
  return organizationBEArray.map((organizationBE) => {
    return mapOrganizationBEsToDTO(organizationBE);
  });
};

export const mapOrganizationDTOToBEForCreate = (
  organization: Partial<Organization>
): Partial<OrganizationBE> => {
  return {
    // id: organization.id,
    status: organization.status ? '1' : '0',
    name: organization.name,
    email: organization.email,
    phone: organization.phone,
    address: organization.address,
    contact_name: organization.contactName,
    contact_mail: organization.contactMail,
    contact_phone: organization.contactPhone,
    billing_name: organization.billingName,
    billing_address: organization.billingAddress,
    tax_number: organization.taxNumber,
  };
};

export const mapOrganizationDTOToBEForUpdate = (
  organization: Partial<Organization>
): Partial<OrganizationBE> => {
  return {
    id: organization.id,
    status: organization.status ? '1' : '0',
    name: organization.name,
    email: organization.email,
    phone: organization.phone,
    address: organization.address,
    contact_name: organization.contactName,
    contact_mail: organization.contactMail,
    contact_phone: organization.contactPhone,
    billing_name: organization.billingName,
    billing_address: organization.billingAddress,
    tax_number: organization.taxNumber,
  };
};
