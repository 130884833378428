import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogOut, Settings, UserSquare } from "lucide-react";

import { useTranslation } from '@/hooks/useTranslation';
import { useAuthContext } from '@/hooks/useAuth';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import {
  Button,
} from "@/components/ui/button";

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar"
import { cn } from "@/lib/utils";
import { UserType } from '@/types/user';
import { LanguageSelector } from '@/components/LanguageSelector';
import { RiAccountBoxFill, RiAccountBoxLine } from '@remixicon/react';

function UserPreview({
  user,
  isOpen,
  languageSelectorDisabled = false,
}: {
  user: UserType,
  isOpen?: boolean,
  languageSelectorDisabled?: boolean,
}) {
  return (
    <div className={cn("flex items-center gap-2", isOpen && "w-full")}>
      <Avatar>
        {/* <AvatarImage src="https://github.com/shadcn.png" alt="@shadcn" /> */}
        <AvatarImage src={user.profilePicture ?? void 0} alt={user.name} />
        <AvatarFallback>{user.name.substring(0, 2)}</AvatarFallback>
      </Avatar>
      {
        isOpen && (
          <>
            <div className="flex flex-col text-start">
              <span className='font-medium'>{user.name}</span>
              <span className='text-xs text-neutral-500'>{user.email}</span>
            </div>
            <LanguageSelector disabled={languageSelectorDisabled} />
          </>
        )
      }
    </div>
  );
}

export function UserNav({ isOpen }: { isOpen: boolean | undefined }) {
  const { t } = useTranslation('common');
  const { logout, user } = useAuthContext();
  const navigate = useNavigate();

  const handleLogout = useCallback(async () => {
    await logout();
  }, [logout]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          onClick={() => {}}
          variant="ghost"
          className="w-full justify-center"
        >
          <UserPreview user={user} isOpen={isOpen} languageSelectorDisabled />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent side="right" sideOffset={25} align="start">
        <DropdownMenuLabel>
          {/* {t('userMenu')} */}
          <UserPreview user={user} isOpen />
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => navigate('/account')} className="flex gap-2">
          <RiAccountBoxLine size={18} />
          <span>{t('myAccount')}</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate('/settings')} className="flex gap-2">
          <Settings size={18} />
          <span>{t('settings')}</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={handleLogout} className="flex gap-2">
          <LogOut size={18} />
          <span>{t('logout')}</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
