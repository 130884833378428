import { FC } from 'react';
import { Button } from '../ui/button';
import { useTranslation } from '@/hooks/useTranslation';
import { toast } from 'sonner';

export const PrivacyPolicySnippetEN: FC = () => {
  const { t } = useTranslation('common');

  const privacyPolicyText = `Data Collection by Third Parties
Good Life Ltd. and Conversion Craft Pop-ups

Our website uses Conversion Craft pop-ups provided by Good Life Ltd. ("Good Life") to improve user experience and collect necessary data. Good Life acts as a data collector and processes personal data on our behalf. The data collected through these pop-ups may include, but is not limited to:

- Contact information (e.g., email addresses, phone numbers)
- User preferences and feedback
- Interaction data (e.g., how users interact with the pop-ups)

Purpose of Data Collection
We use the data collected by Good Life through Conversion Craft for the following purposes:

- Increasing user engagement and experience on our website
- Providing personalized offers and promotions
- Conducting surveys and collecting feedback to improve our services
- Marketing and communication purposes, if we have received consent for this
- Training machine learning models to improve our services and user experience

Legal Basis for Data Processing
Good Life processes personal data based on the following legal grounds:

- Consent: Through interaction with Conversion Craft pop-ups and explicit consent given, users agree to the collection and processing of their personal data for the described purposes, including the training of machine learning models.
- Legitimate interests: Data collection is necessary to improve our services and user engagement, including the training of machine learning models, provided that these interests are not overridden by individuals' rights.

Data Sharing and Transfer
We may share the data collected by Good Life through Conversion Craft with:

- Our internal departments responsible for marketing, customer service, and website management
- Third-party service providers who assist in data processing and analysis
- Machine learning model developers who use the data to train and improve algorithms for enhancing our services

We ensure that all third parties comply with GDPR requirements for the protection of personal data.

Data Retention
We retain personal data collected by Good Life through Conversion Craft only for as long as necessary to fulfill the purposes described here, unless the law prescribes or permits a longer retention period.

User Rights
Users have the following rights regarding their personal data:

- Right of access: Users can request access to their personal data collected by Good Life through Conversion Craft.
- Right to rectification: Users can request correction of inaccurate or incomplete personal data.
- Right to erasure: Users can request the deletion of their personal data under certain conditions.
- Right to restrict processing: Users can request the restriction of processing of their personal data.
- Right to data portability: Users can request to receive their personal data in a structured, commonly used, and machine-readable format.
- Right to object: Users can object to the processing of their personal data for certain purposes.

To exercise these rights, users can contact us at [client contact information] or directly contact Good Life:

Good Life Ltd.
[Good Life Ltd. Contact Information]
[Good Life Ltd. Email Address]

Changes to This Section
We may update this section of our privacy policy from time to time to reflect changes in our data handling practices or regulatory requirements. We encourage users to review this section periodically.`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(privacyPolicyText).then(
      () => {
        toast.success(
          t('privacyStatementHasBeenSuccessfullyCopiedToTheClipboard')
        );
      },
      (err) => {
        console.error('Failed to copy text: ', err);
        toast.error(t('anErrorOccurredWhileCopyingTheText'));
      }
    );
  };

  return (
    <div className="max-w-4xl mx-auto bg-white border rounded-lg p-6">
      <h1 className="text-xl font-bold mb-6 text-gray-800">
        Data Collection by Third Parties
      </h1>
      <h2 className="text-lg font-semibold mb-4 text-gray-700">
        Good Life Ltd. and Conversion Craft Pop-ups
      </h2>

      <p className="mb-4 text-gray-600">
        Our website uses Conversion Craft pop-ups provided by Good Life Ltd.
        ("Good Life") to improve user experience and collect necessary data.
        Good Life acts as a data collector and processes personal data on our
        behalf. The data collected through these pop-ups may include, but is not
        limited to:
      </p>

      <ul className="list-disc pl-6 mb-4 text-gray-600">
        <li>Contact information (e.g., email addresses, phone numbers)</li>
        <li>User preferences and feedback</li>
        <li>Interaction data (e.g., how users interact with the pop-ups)</li>
      </ul>

      <h3 className="font-semibold mt-6 mb-3 text-gray-700">
        Purpose of Data Collection
      </h3>
      <p className="mb-2 text-gray-600">
        We use the data collected by Good Life through Conversion Craft for the
        following purposes:
      </p>

      <ul className="list-disc pl-6 mb-4 text-gray-600">
        <li>Increasing user engagement and experience on our website</li>
        <li>Providing personalized offers and promotions</li>
        <li>
          Conducting surveys and collecting feedback to improve our services
        </li>
        <li>
          Marketing and communication purposes, if we have received consent for
          this
        </li>
        <li>
          Training machine learning models to improve our services and user
          experience
        </li>
      </ul>

      <h3 className="font-semibold mt-6 mb-3 text-gray-700">
        Legal Basis for Data Processing
      </h3>
      <p className="mb-2 text-gray-600">
        Good Life processes personal data based on the following legal grounds:
      </p>

      <ul className="list-disc pl-6 mb-4 text-gray-600">
        <li>
          <strong>Consent:</strong> Through interaction with Conversion Craft
          pop-ups and explicit consent given, users agree to the collection and
          processing of their personal data for the described purposes,
          including the training of machine learning models.
        </li>
        <li>
          <strong>Legitimate interests:</strong> Data collection is necessary to
          improve our services and user engagement, including the training of
          machine learning models, provided that these interests are not
          overridden by individuals' rights.
        </li>
      </ul>

      <h3 className="font-semibold mt-6 mb-3 text-gray-700">
        Data Sharing and Transfer
      </h3>
      <p className="mb-2 text-gray-600">
        We may share the data collected by Good Life through Conversion Craft
        with:
      </p>

      <ul className="list-disc pl-6 mb-4 text-gray-600">
        <li>
          Our internal departments responsible for marketing, customer service,
          and website management
        </li>
        <li>
          Third-party service providers who assist in data processing and
          analysis
        </li>
        <li>
          Machine learning model developers who use the data to train and
          improve algorithms for enhancing our services
        </li>
      </ul>

      <p className="mb-4 text-gray-600">
        We ensure that all third parties comply with GDPR requirements for the
        protection of personal data.
      </p>

      <h3 className="font-semibold mt-6 mb-3 text-gray-700">Data Retention</h3>
      <p className="mb-4 text-gray-600">
        We retain personal data collected by Good Life through Conversion Craft
        only for as long as necessary to fulfill the purposes described here,
        unless the law prescribes or permits a longer retention period.
      </p>

      <h3 className="font-semibold mt-6 mb-3 text-gray-700">User Rights</h3>
      <p className="mb-2 text-gray-600">
        Users have the following rights regarding their personal data:
      </p>

      <ul className="list-disc pl-6 mb-4 text-gray-600">
        <li>
          Right of access: Users can request access to their personal data
          collected by Good Life through Conversion Craft.
        </li>
        <li>
          Right to rectification: Users can request correction of inaccurate or
          incomplete personal data.
        </li>
        <li>
          Right to erasure: Users can request the deletion of their personal
          data under certain conditions.
        </li>
        <li>
          Right to restrict processing: Users can request the restriction of
          processing of their personal data.
        </li>
        <li>
          Right to data portability: Users can request to receive their personal
          data in a structured, commonly used, and machine-readable format.
        </li>
        <li>
          Right to object: Users can object to the processing of their personal
          data for certain purposes.
        </li>
      </ul>

      <p className="mb-4 text-gray-600">
        To exercise these rights, users can contact us at [client contact
        information] or directly contact Good Life:
      </p>

      <address className="mb-4 text-gray-600">
        Good Life Ltd.
        <br />
        [Good Life Ltd. Contact Information]
        <br />
        [Good Life Ltd. Email Address]
      </address>

      <h3 className="font-semibold mt-6 mb-3 text-gray-700">
        Changes to This Section
      </h3>
      <p className="mb-4 text-gray-600">
        We may update this section of our privacy policy from time to time to
        reflect changes in our data handling practices or regulatory
        requirements. We encourage users to review this section periodically.
      </p>

      <div className="w-full flex items-center justify-center">
        <Button onClick={copyToClipboard} variant="conversionCraft" size="sm">
          {t('copyToClipboard')}
        </Button>
      </div>
    </div>
  );
};
