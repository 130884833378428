import { PermissionType, UserType } from '@/types/user';
import React, { useContext } from 'react';

export interface AuthContextParams {
  user: UserType;
  token: string;
  permissions: PermissionType[];
  client: string | null;
  isLoggedIn: boolean;
  login: (
    user: UserType,
    authResponse: {
      access_token: string;
      token_type: string;
      expires_in: string;
    },
    permissions: PermissionType[],
    callback: VoidFunction
  ) => void;
  setToken: (token: string) => void;
  logout: (callback?: VoidFunction) => void;
}

export const AuthContext = React.createContext<AuthContextParams>(null!);

export function useAuthContext(): AuthContextParams {
  return useContext<AuthContextParams>(AuthContext);
}
