import { Website, WebsiteBE } from '@/types/website';

export const mapWebsiteBEsToDTO = (websiteBEArray: WebsiteBE[]): Website[] => {
  return websiteBEArray.map((websiteBE) => {
    return {
      id: websiteBE.id.toString(),
      name: websiteBE.name,
      url: websiteBE.url,
      created: websiteBE.created_at,
      updated: websiteBE.updated_at,
      accessCodeId: websiteBE.access_code,
      status: websiteBE.status as 'active' | 'missing_code',
      sitemapUrl: websiteBE.sitemap_url,
      isUnas: Boolean(websiteBE.is_unas_page),
      unasApiKey: '',
      showPopupsOnAllPage: Boolean(websiteBE.display_on_all_page),
      blockWebsite: Boolean(websiteBE.disabled),
      blockedBySystem: Boolean(websiteBE.auto_disabled),
    };
  });
};

export const mapWebsiteDTOToBEForCreate = (
  website: Partial<Website>
): Partial<WebsiteBE> => {
  return {
    name: website.name,
    url: website.url,
    sitemap_url: website.sitemapUrl,
  };
};

export const mapWebsiteDTOToBEForUpdate = (
  website: Partial<Website>
): Partial<WebsiteBE> => {
  return {
    id: Number(website.id),
    name: website.name,
    url: website.url,
    sitemap_url: website.sitemapUrl,
    access_code: website.accessCodeId,
    processing_status: website.status,
    is_unas_page: Number(Boolean(website.unasApiKey)),
    is_active: Number(website.isActive),
    display_on_all_page: Number(website.showPopupsOnAllPage),
    disabled: Number(website.blockWebsite),
    auto_disabled: Number(website.blockedBySystem),
  };
};
