import { ListResponseType, ListResponseTypeDTO } from '@/hooks/useApi';

export const mapListResponse = <T, K>(
  response: ListResponseType<T>,
  dataMapperFn: (dataArray: T[]) => K[]
): ListResponseTypeDTO<K> => {
  let data: T[] = [];
  let count = 0;
  let per_page = 0;
  let last_page = 1;
  let current_page = 1;
  let total = 1;

  if (response?.data) {
    data = response.data;
    count = response.count;
    per_page = response.per_page;
    last_page = response?.last_page ?? 1;
    current_page = response?.current_page ?? 1;
    total = response?.total ?? 1;
  }

  return {
    data: dataMapperFn(data),
    count,
    perPage: per_page,
    lastPage: last_page,
    currentPage: current_page,
    total,
  };
};
