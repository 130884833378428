import { Campaign, CampaignBE, CampaignGoal } from '@/types/campaign';

export const mapCampaignBEsToDTO = (campaignBE: CampaignBE): Campaign => {
  return {
    id: campaignBE.id.toString(),
    name: campaignBE.campaign_name,
    created: campaignBE.created_at,
    updated: campaignBE.updated_at,
    goal: campaignBE.goal as CampaignGoal,
    website: {
      id: campaignBE.website_id.toString(),
      name: campaignBE.slug,
      url: '',
      created: '',
      updated: '',
    },
    startDate: campaignBE.start_date,
    endDate: campaignBE.end_date,
    isActive: Boolean(campaignBE.is_running),
    isEnded: Boolean(campaignBE.is_ended),
    isLooping: Boolean(campaignBE.is_can_auto_continue),
  };
};

export const mapCampaignsBEsToDTO = (
  campaignBEArray: CampaignBE[]
): Campaign[] => {
  return campaignBEArray.map((campaignBE) => {
    return mapCampaignBEsToDTO(campaignBE);
  });
};

export const mapCampaignDTOToBEForCreate = (
  campaign: Partial<Campaign>
): Partial<CampaignBE> => {
  const websiteId = campaign?.website?.id;

  return {
    campaign_name: campaign.name,
    goal: campaign.goal,
    website_id: websiteId ? Number(campaign?.website?.id) : undefined,
    start_date: campaign.startDate,
    end_date: campaign.endDate,
    is_can_auto_continue: Number(campaign.isLooping),
  };
};

export const mapCampaignDTOToBEForUpdate = (
  campaign: Partial<Campaign>
): Partial<CampaignBE> => {
  return {
    campaign_name: campaign.name,
    goal: campaign.goal,
    website_id: Number(campaign?.website?.id),
    start_date: campaign.startDate,
    end_date: campaign.endDate,
    is_can_auto_continue: Number(campaign.isLooping),
  };
};
