import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { produce } from 'immer';
import { Menu } from '@/types/menus';
import { Organization } from '@/types/organization';

export type OrganizationStore = {
  organization: Organization | null;
  availableOrganizations: Organization[];
  menu: Menu | null;
  setOrganization: (organization: Organization | null) => void;
  setMenu: (menu: Menu) => void;
  setAvailableOrganizations: (organizations: Organization[]) => void;
};

export const useOrganizationStore = create<OrganizationStore>()(
  devtools(
    persist(
      (set) => ({
        organization: null,
        menu: null,
        availableOrganizations: [],
        setOrganization: (organization: Organization | null) => {
          set(
            produce((state: OrganizationStore): void => {
              if (!organization) {
                localStorage.removeItem('selected-organization');
              } else {
                localStorage.setItem(
                  'selected-organization',
                  organization.id.toString()
                );
              }
              state.organization = organization;
            })
          );
        },
        setMenu: (menu: Menu) => {
          set(
            produce((state: OrganizationStore): void => {
              state.menu = menu;
            })
          );
        },
        setAvailableOrganizations: (clients: Organization[]) => {
          set(
            produce((state: OrganizationStore): void => {
              state.availableOrganizations = clients;
              if (!state.organization) {
                state.organization = clients[0];
              }
            })
          );
        },
      }),
      {
        name: 'organization',
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
