import { Link, useLocation } from 'react-router-dom';

import { cn } from "@/lib/utils";
import { useStore } from "@/hooks/useStore";
import { Button } from "@/components/ui/button";
import { useSidebarToggle } from "@/stores/sidebar";
import { Menu } from "./Menu";
import { SidebarToggle } from "./SidebarToggle";

export function Sidebar() {
  const sidebar = useStore(useSidebarToggle, (state) => state);
  
  const location = useLocation();
  const isOnEditor = location.pathname.includes('/editor');
  
  if (isOnEditor) {
    return null;
  }

  // if(!sidebar) return null;

  return (
    <aside
      className={cn(
        "fixed top-0 left-0 z-20 h-screen -translate-x-full lg:translate-x-0 transition-[width] ease-in-out duration-300 bg-background",
        sidebar?.isOpen === false ? "w-[90px]" : "w-72"
      )}
    >
      <SidebarToggle isOpen={sidebar?.isOpen} setIsOpen={sidebar?.setIsOpen} />
      <div className="relative h-full flex flex-col px-3 py-4 overflow-y-auto shadow-md dark:shadow-zinc-800">
        <Button
          className={cn(
            "transition-transform ease-in-out duration-300 mb-1",
            sidebar?.isOpen === false ? "translate-x-1" : "translate-x-0"
          )}
          variant="link"
          asChild
        >
          <Link to="/dashboard" className="flex items-center gap-2">
            <img src="/cc_vertical_128.png" alt="ConversionCraft" className="h-8 object-contain" />
            <div
              className={`flex flex-col justify-end truncate ${!sidebar?.isOpen ? 'invisible w-0' : 'visible w-auto'}`}
            >
              <img src="/cc_vertical_title.png" alt="ConversionCraft" className="h-5" />
            </div>
            {/* <h1
              className={cn(
                "font-bold text-lg whitespace-nowrap transition-[transform,opacity,display] ease-in-out duration-300",
                sidebar?.isOpen === false
                  ? "-translate-x-96 opacity-0 hidden"
                  : "translate-x-0 opacity-100"
              )}
            >
              Brand
            </h1> */}
          </Link>
        </Button>
        <Menu isOpen={sidebar?.isOpen} />
      </div>
    </aside>
  );
}
