// import { LanguageSelector } from './LanguageSelector';
import { ShowOnboardingModal } from './ShowOnboardingModal';
import { ColorSwitch } from './layout/ColorSwitch';
// import { UserNav } from './UserNav';
// import { SidebarMobile } from './layout/SidebarMobile';
import { Notifications } from './Notifications';
import { OrganizationSelector } from './OrganizationSelector';
import { useLocation } from 'react-router-dom';
// import { LogoWithNavigation } from './LogoWithNavigation';
import { SheetMenu } from "./layout/Sidebar2/SheetMenu";
import { NavbarSearch } from './layout/NavbarSearch';

export const Navbar = () => {
  const location = useLocation();
  const isOnEditor = location.pathname.includes('/editor');

  if (isOnEditor) {
    return null;
  }

  return (
    <header className="sticky top-0 z-10 w-full bg-background md:bg-zinc-50 md:dark:bg-gray-900">
      <div className="mx-4 sm:mx-8 flex h-14 items-center">
        <div className="flex items-center space-x-4 lg:space-x-0">
          <SheetMenu />
          {/* <h1 className="font-bold">{title}</h1> */}
          <NavbarSearch />
        </div>
        <div className="flex flex-1 items-center justify-end gap-2">
          <OrganizationSelector />
          <Notifications />
          {/* <LanguageSelector /> */}
          <ColorSwitch />
          {/* <UserNav /> */}
        </div>
      </div>
      <ShowOnboardingModal />
    </header>
    // <header
    //   className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6 shadow-md z-20"
    //   id="navbar"
    // >
    //   {/* <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
    //     <LogoWithNavigation />
    //   </nav> */}
    //   {/* <SidebarMobile /> */}
    //   <SheetMenu />
    //   <div className="flex w-full items-center justify-end gap-2 md:ml-auto md:gap-2 lg:gap-4">
    //     <OrganizationSelector />
    //     <Notifications />
    //     <LanguageSelector />
    //     <ColorSwitch />
    //     {/* <CurrencySelector /> */}
    //     <UserNav />
    //   </div>
    //   <ShowOnboardingModal />
    // </header>
  );
};
