import { useState } from 'react';
import { Calendar as CalendarIcon } from 'lucide-react';
import { toast } from 'sonner';
import { useTranslation } from '@/hooks/useTranslation';
import { Modal } from './Modal';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { Checkbox } from '../ui/checkbox';
import { DialogFooter } from '../ui/dialog';
import { Button } from '../ui/button';
import { useModalContext } from '@/hooks/useModalContext';
import { format } from 'date-fns';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { Calendar } from '../ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import {
  cn,
  convertDateToBackendFormat,
  handleCheckboxChange,
} from '@/lib/utils';
import { useCreateCampaign } from '@/hooks/campaign/useCreateCampaign';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { useCreateBlogPost } from '@/hooks/blog/useCreateBlogPost';

export const CreateBlogPostModal = () => {
  const { t } = useTranslation('common');
  const { closeModal, isModalOpen } = useModalContext();

  const handleClose = () => {
    closeModal('createBlogPost');
  };

  const [status, setStatus] = useState('0');
  const [content, setContent] = useState('');
  const [listDescription, setListDescription] = useState('');
  const [title, setTitle] = useState('');
  const [seoSlug, setSeoSlug] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [seoDescription, setSeoDescription] = useState('');

  const [website, setWebsite] = useState('');
  const [name, setName] = useState('');
  const [goal, setGoal] = useState('leads');
  const [startDate, setStartDate] = useState<Date|null>(null);
  const [endDate, setEndDate] = useState<Date|null>(null);
  const [isLooping, setIsLooping] = useState(false);

  const { mutate: createBlogPost, isPending: isCreatingBlogPost } =
    useCreateBlogPost({
      onSuccess: () => {
        toast.success(t('campaignCreatedSuccessfully'));
        handleClose();
      },
      onError: () => {
        toast.error(t('failedToCreateCampaign'));
      },
    });

  const handleSave = () => {
    createBlogPost({
      status,
      content,
      listDescription,
      title,
      seoSlug,
      seoTitle,
      seoDescription,
      // website: { id: website },
      // name,
      // goal,
      // // TODO: Fix date format on BE
      // startDate: convertDateToBackendFormat(startDate),
      // endDate: convertDateToBackendFormat(endDate),
      // isLooping,
    });
  };

  return (
    <Modal modalType="createBlogPost" title={t('createBlogPost')}>
      <div className="space-y-4">
        <div>
          <Label htmlFor="status">{t('status')}</Label>
          <Select value={status} onValueChange={(v) => setStatus(v)}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder={t('status')} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="0">{t('blog.status.0')}</SelectItem>
                <SelectItem value="1">{t('blog.status.1')}</SelectItem>
              </SelectContent>
            </Select>
        </div>
        {/* <div className="w-full grid grid-cols-1 lg:grid-cols-2 items-center">
          <Label htmlFor="start-date" className="col-span-1">
            {t('campaignStartDate')}
          </Label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={'outline'}
                className={cn(
                  'justify-start text-left font-normal col-span-1',
                  !startDate && 'text-muted-foreground'
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {startDate ? (
                  format(startDate, 'PPP')
                ) : (
                  <span>{t('pickAStartDate')}</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                id="start-date"
                mode="single"
                selected={startDate}
                onSelect={setStartDate}
                initialFocus
                fromDate={new Date()}
                toDate={endDate}
              />
            </PopoverContent>
          </Popover>
        </div>
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 items-center">
          <Label htmlFor="end-date" className="col-span-1">
            {t('campaignEndDate')}
          </Label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={'outline'}
                className={cn(
                  'justify-start text-left font-normal col-span-1',
                  !startDate && 'text-muted-foreground'
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {endDate ? (
                  format(endDate, 'PPP')
                ) : (
                  <span>{t('pickAnEndDate')}</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                id="end-date"
                mode="single"
                selected={endDate}
                onSelect={setEndDate}
                initialFocus
                fromDate={startDate}
              />
            </PopoverContent>
          </Popover>
        </div>
        <div className="flex items-center gap-2">
          <Checkbox
            id="auto-restart"
            checked={isLooping}
            onCheckedChange={handleCheckboxChange(setIsLooping)}
          />
          <Label htmlFor="auto-restart">
            {t('theCampaignCanBeRestartedAutomatically')}
          </Label>
        </div> */}
        <DialogFooter className="mt-4">
          <Button variant="ghost" onClick={handleClose}>
            {t('close')}
          </Button>
          <Button
            onClick={handleSave}
            disabled={isCreatingBlogPost}
            className="flex gap-1"
            variant="conversionCraft"
          >
            {isCreatingBlogPost && <LoadingIndicator size="small" />}
            {t('save')}
          </Button>
        </DialogFooter>
      </div>
    </Modal>
  );
};
