import { Link } from 'react-router-dom';
import { MenuIcon } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Menu } from "./Menu";
import {
  Sheet,
  SheetHeader,
  SheetContent,
  SheetTrigger,
} from "@/components/ui/sheet";

export function SheetMenu() {
  return (
    <Sheet>
      <SheetTrigger className="lg:hidden" asChild>
        <Button className="h-8" variant="outline" size="icon">
          <MenuIcon size={20} />
        </Button>
      </SheetTrigger>
      <SheetContent className="sm:w-72 px-3 h-full flex flex-col" side="left" aria-describedby='menu'>
        <SheetHeader>
          <Button
            className="flex justify-center items-center pb-2 pt-1"
            variant="link"
            asChild
          >
            <Link to="/dashboard" className="flex items-center gap-2">
              <img src="/cc_vertical_128.png" alt="ConversionCraft" className="h-8 object-contain" />
              <img src="/cc_vertical_title.png" alt="ConversionCraft" className="h-5" />
            </Link>
          </Button>
        </SheetHeader>
        <Menu isOpen />
      </SheetContent>
    </Sheet>
  );
}