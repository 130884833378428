import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';

import { Sidebar as Sidebar2 } from './components/layout/Sidebar2/Sidebar';
import { Navbar } from './components/Navbar';
import RequireAuth from './components/layout/RequireAuth';
import { ModelsContainer } from './components/layout/ModalsContainer';
import {
  GROWTHBOOK_API_HOST,
  GROWTHBOOK_CLIENT_KEY,
  IS_GROWTBOOK_DEV_MODE_ENABLED,
  IS_GROWTHBOOK_AVAILABLE,
} from './utils/growthbook';
import { AbilityLoader } from './providers/AbilityProvider';

import { useStore } from '@/hooks/useStore';
import { useSidebarToggle } from '@/stores/sidebar';
import { cn } from './lib/utils';

const growthbook = new GrowthBook({
  apiHost: GROWTHBOOK_API_HOST,
  clientKey: GROWTHBOOK_CLIENT_KEY,
  enableDevMode: IS_GROWTBOOK_DEV_MODE_ENABLED,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export function AppLayout() {
  const sidebar = useStore(useSidebarToggle, (state) => state);

  useEffect(() => {
    if (IS_GROWTHBOOK_AVAILABLE) {
      growthbook.init({ streaming: true });
    }
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <RequireAuth>
        <QueryClientProvider client={queryClient}>
          <AbilityLoader>
            {/* <div className="flex min-h-screen w-full flex-col bg-muted/40"> */}
              {/* <Sidebar /> */}
              {/* <NewSidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} /> */}
              <Sidebar2 />
              <main
                // id="content"
                // className="flex flex-col sm:gap-4 sm:pl-14"
                // className={`pt-16 transition-[margin] md:pt-0 ${isCollapsed ? 'md:ml-14' : 'md:ml-64'} h-full`}
                className={cn(
                  "min-h-[calc(100vh_)] transition-[margin-left] ease-in-out duration-300 bg-zinc-50 dark:bg-gray-900",
                  sidebar?.isOpen === false ? "lg:ml-[90px]" : "lg:ml-72"
                )}
              >
                <Navbar />
                <div className="container pt-8 pb-8 px-4 sm:px-8">
                  <Outlet />
                </div>
              </main>
              {/* <div
                className="fixed -z-10 top-0 left-0 w-full h-full opacity-10"
                style={{
                  backgroundImage:
                    'repeating-linear-gradient(45deg, #0369a1 0, #0369a1 2px, transparent 0, transparent 50%)',
                  backgroundSize: '8px 8px',
                  backgroundColor: '#f0f9ff',
                }}
              /> */}
              <ModelsContainer />
            {/* </div> */}
          </AbilityLoader>
        </QueryClientProvider>
      </RequireAuth>
    </GrowthBookProvider>
  );
}
