import { useTranslation } from '@/hooks/useTranslation';
import { Modal } from './Modal';
import { useState } from 'react';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { DialogFooter } from '../ui/dialog';
import { Button } from '../ui/button';
import { useModalContext } from '@/hooks/useModalContext';
import { InputPasteButton } from '../InputPasteButton';
import { useCreateWebsite } from '@/hooks/website/useCreateWebsite';
import { toast } from 'sonner';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { useNavigate } from 'react-router-dom';

export const CreateBasicWebsiteModal = () => {
  const { t } = useTranslation('common');
  const { closeModal } = useModalContext();
  const navigate = useNavigate();

  const { mutate: createCampaign, isPending: isCreatingWebsite } =
    useCreateWebsite({
      onSuccess: () => {
        toast.success(t('websiteCreatedSuccessfully'));
        handleClose();
        navigate('/websites');
      },
      onError: () => {
        toast.error(t('failedToCreateWebsite'));
      },
    });

  const handleClose = () => {
    closeModal('createNewBasicWebsite');
  };

  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [sitemapUrl, setSitemapUrl] = useState('');

  const handleSave = () => {
    createCampaign({
      name,
      url,
      sitemapUrl,
    });
  };

  const handlePaste = async (
    setValue: React.Dispatch<React.SetStateAction<string>>
  ) => {
    try {
      const text = await navigator.clipboard.readText();
      setValue(text);
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
    }
  };

  return (
    <Modal modalType="createNewBasicWebsite" title={t('createNewWebsite')}>
      <div className="space-y-4">
        <div className="relative">
          <Label htmlFor="name">{t('website')}</Label>
          <Input
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="!bg-white"
          />
          <InputPasteButton onClick={() => handlePaste(setName)} />
        </div>
        <div className="relative">
          <Label htmlFor="website-url">{t('websiteURL')}</Label>
          <Input
            id="website-url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <InputPasteButton onClick={() => handlePaste(setUrl)} />
        </div>
        <div className="relative">
          <Label htmlFor="sitemap-url">{t('sitemapURL')}</Label>
          <Input
            id="sitemap-url"
            value={sitemapUrl}
            onChange={(e) => setSitemapUrl(e.target.value)}
          />
          <InputPasteButton onClick={() => handlePaste(setSitemapUrl)} />
        </div>
        <DialogFooter className="mt-4">
          <Button
            onClick={handleSave}
            disabled={isCreatingWebsite}
            className="flex gap-1"
            variant="conversionCraft"
          >
            {isCreatingWebsite && <LoadingIndicator size="small" />}
            {t('save')}
          </Button>
        </DialogFooter>
      </div>
    </Modal>
  );
};
