import { FC } from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from '@/components/ui/card';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { InfoIcon, ExternalLink } from 'lucide-react';

export const ShopifyGuide: FC = () => {
  const steps = [
    {
      title: 'Copy the ConversionCreaft snippet',
      description:
        'Log in to your ConversionCreaft account and find your tracking code snippet.',
    },
    {
      title: 'Access Shopify admin',
      description: 'Log in to your Shopify admin panel.',
    },
    {
      title: 'Navigate to theme settings',
      description:
        'Go to Online Store > Themes > Current theme > Actions > Edit code.',
    },
    {
      title: 'Locate the appropriate file',
      description: 'Find the theme.liquid file in the Layout folder.',
    },
    {
      title: 'Paste the snippet',
      description:
        'Paste the ConversionCreaft snippet just before the closing </head> tag.',
    },
    {
      title: 'Save changes',
      description: "Click 'Save' to apply the changes to your Shopify theme.",
    },
  ];

  return (
    <Card className="w-full max-w-3xl mx-auto">
      <CardHeader>
        <CardTitle>How to Add ConversionCraft Snippet to Shopify</CardTitle>
        <CardDescription>
          Follow these steps to add your ConversionCraft tracking code to your
          Shopify store.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ol className="list-decimal list-inside space-y-4 mb-8">
          {steps.map((step, index) => (
            <li key={index}>
              <span className="font-bold">{step.title}</span>
              <p className="ml-5 text-sm text-gray-600">{step.description}</p>
              {index === 4 && (
                <Alert className="my-4 w-full max-w-2xl">
                  <InfoIcon className="h-4 w-4" />
                  <AlertTitle>Tip</AlertTitle>
                  <AlertDescription className="w-full max-w-2xl overflow-x-scroll">
                    Paste your snippet just before the closing &lt;/head&gt; tag
                    in the theme.liquid file:
                    <pre className="mt-2 bg-gray-100 p-2 rounded">
                      {`<head>\n  <!-- Other head content -->\n  <Paste your code here>\n</head>`}
                    </pre>
                  </AlertDescription>
                </Alert>
              )}
            </li>
          ))}
        </ol>
      </CardContent>
      <CardFooter>
        <a
          href="https://help.shopify.com/en/manual/online-store/themes/theme-structure/extend/edit-theme-code"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center text-blue-600 hover:underline"
        >
          Learn more about editing Shopify theme code
          <ExternalLink className="ml-1 h-4 w-4" />
        </a>
      </CardFooter>
    </Card>
  );
};
