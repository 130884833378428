export const AUTH = 'auth';
export const CAMPAIGN = 'campaign';
export const CAMPAIGNS = 'campaigns';
export const CAMPAIGN_POPUP = 'campaign_popup';
export const CAMPAIGN_STATS = 'campaign_stats';
export const CAMPAIGN_POPUP_STATS = 'campaign_popup_stats';
export const DASHBOARD_STATS = 'dashboard_stats';
export const POPUPS_STATS = 'popups_stats';
export const EDITOR = 'editor';
export const END = 'end';
export const EXPORT = 'export';
export const IFRAME = 'iframe';
export const LOGIN = 'login';
export const ME = 'me';
export const ORGANIZATION = 'organization';
export const PAGES = 'pages';
export const POPUP = 'popup';
export const POPUPS = 'popups';
export const PROFILE = 'profile';
export const RESTART = 'restart';
export const RESUME = 'resume';
export const STATS = 'stats';
export const SUBSCRIBERS = 'subscribers';
export const PRE_REGISTRATIONS = 'pre-registrations';
export const SUSPEND = 'suspend';
export const WEBSITE_PAGE_TAGS = 'website-page-tags';
export const USER = 'user';
export const USERS = 'users';
export const INVITATIONS = 'pendinvite';
export const WEBSITES = 'websites';

export const BLOG_POSTS = 'blog-posts';
