import { Ellipsis } from "lucide-react";
import { NavLink as Link } from 'react-router-dom';

import { cn } from "@/lib/utils";
import { Group, Menu as MenuType, useGetMenuList } from "./items";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { CollapseMenuButton } from "./CollapseMenuButton";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider
} from "@/components/ui/tooltip";
import { useCheckActiveNav } from "@/hooks/useCheckActiveNav";
import { UserNav } from "./UserNav";
import { Separator } from "@/components/ui/separator";

interface MenuProps {
  isOpen: boolean | undefined;
}

function MenuItem({
  submenus,
  isOpen,
  href,
  icon: Icon,
  label,
}: MenuType & { isOpen: boolean | undefined }) {
  const { checkActiveNav } = useCheckActiveNav();

  return submenus.length === 0 ? (
    <div className="w-full">
      <TooltipProvider disableHoverableContent>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <Button
              variant={checkActiveNav(href) ? "conversionCraft" : "ghost"}
              className="w-full justify-start h-10 mb-1"
              asChild
            >
              <Link to={href}>
                <span
                  className={cn(isOpen === false ? "" : "mr-4")}
                >
                  <Icon size={18} />
                </span>
                <p
                  className={cn(
                    "max-w-[200px] truncate",
                    isOpen === false
                      ? "-translate-x-96 opacity-0"
                      : "translate-x-0 opacity-100"
                  )}
                >
                  {label}
                </p>
              </Link>
            </Button>
          </TooltipTrigger>
          {isOpen === false && (
            <TooltipContent side="right">
              {label}
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>
    </div>
  ) : (
    <div className="w-full">
      <CollapseMenuButton
        icon={Icon}
        label={label}
        href={href}
        // active={checkActiveNav(href)}
        submenus={submenus}
        isOpen={isOpen}
      />
    </div>
  );
}

function MenuList({
  groupLabel,
  showLabelOnCollapse,
  menus,
  isOpen,
}: Group & { isOpen: boolean | undefined }) {
  return (
    <li className={cn("w-full", groupLabel ? "pt-5" : "")}>
      {(isOpen && groupLabel) || isOpen === undefined ? (
        <p className="text-sm font-medium text-muted-foreground px-4 pb-2 max-w-[248px] truncate">
          {groupLabel}
        </p>
      ) : !isOpen && isOpen !== undefined && groupLabel && (showLabelOnCollapse ?? true) ? (
        <TooltipProvider>
          <Tooltip delayDuration={100}>
            <TooltipTrigger className="w-full">
              <div className="w-full flex justify-center items-center">
                <Ellipsis className="h-5 w-5" />
              </div>
            </TooltipTrigger>
            <TooltipContent side="right">
              <p>{groupLabel}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        <p className="pb-2"></p>
      )}
      {menus.map((menuItem, index) => <MenuItem key={index} {...menuItem} isOpen={isOpen} />)}
    </li>
  );
}

export function Menu({ isOpen }: MenuProps) {
  // const location = useLocation();
  const { appMenu, bottomMenu } = useGetMenuList();

  return (
    <ScrollArea className="[&>div>div[style]]:!block">
      <nav className="mt-2 h-full w-full">
        <ul className="flex flex-col min-h-[calc(100vh-48px-36px-16px-32px)] lg:min-h-[calc(100vh-32px-40px-32px)] items-start space-y-1 px-2">
          {appMenu.map((group, index) =>
            <MenuList key={index} {...group} isOpen={isOpen} />
          )}
          <li className="w-full grow flex items-end">
            <ul className="w-full">
              {bottomMenu.map((group, index) =>
                <MenuList key={index} {...group} isOpen={isOpen} />
              )}
              <Separator className="my-2" />
              <li>
                <UserNav isOpen={isOpen} />
              </li>
              {/* <li>
                <TooltipProvider disableHoverableContent>
                  <Tooltip delayDuration={100}>
                    <TooltipTrigger asChild>
                      <Button
                        onClick={() => {}}
                        variant="outline"
                        className="w-full justify-center h-10 mt-5"
                      >
                        <span className={cn(isOpen === false ? "" : "mr-4")}>
                          <LogOut size={18} />
                        </span>
                        <p
                          className={cn(
                            "whitespace-nowrap",
                            isOpen === false ? "opacity-0 hidden" : "opacity-100"
                          )}
                        >
                          Sign out
                        </p>
                      </Button>
                    </TooltipTrigger>
                    {isOpen === false && (
                      <TooltipContent side="right">Sign out</TooltipContent>
                    )}
                  </Tooltip>
                </TooltipProvider>
              </li> */}
            </ul>
          </li>
        </ul>
      </nav>
    </ScrollArea>
  );
}
