import { ReactNode, useEffect, useState } from "react";
import { createMongoAbility, AnyMongoAbility } from "@casl/ability";
import { useGrowthBook } from '@growthbook/growthbook-react';

// import { Permit, permitState } from "permit-fe-sdk";
import { AbilityContext } from "@/contexts/AbilityContext";
import { useAuthContext } from "@/hooks/useAuth";
import { useOrganizationStore } from "@/stores/organization";

export type AbilityLoaderProps = {
  children: ReactNode;
};

export const AbilityLoader = ({ children }: AbilityLoaderProps) => {
  // const { isSignedIn, user } = useUser();
  const gb = useGrowthBook();
  const organization = useOrganizationStore(state => state.organization);
  const { isLoggedIn, permissions, user } = useAuthContext();
  const [ability, setAbility] = useState<AnyMongoAbility>(createMongoAbility([]));

  useEffect(() => {
    const getAbility = async () => createMongoAbility(permissions.map((p) => ({ action: p.action, subject: p.resource })));

    if (isLoggedIn) {
      getAbility().then((caslAbility) => {
        setAbility(caslAbility);
      });

      gb.setAttributes({
        id: user?.id,
        organizationId: organization?.id,
      });
    }
  }, [isLoggedIn, permissions, user, gb, organization]);

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};