import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { produce } from 'immer';
import { PermissionType, UserType } from '@/types/user';

export type UserStore = {
  user: UserType | null;
  permissions: PermissionType[],
  token?: string;
  isLoggedIn: boolean;
  setUser: (user: UserType) => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  setToken: (token: string) => void;
  setPermissions: (permissions: PermissionType[]) => void;
  login: (user: UserType, token: string, permissions?: PermissionType[]) => void;
  logout: () => void;
  wasMainOnboardingShown: boolean;
  setWasMainOnboardingShown: () => void;
  wasMainWalkthroughShown: boolean;
  setWasMainWalkthroughShown: () => void;
};

type UserDefaultStore = Pick<UserStore, 'user' | 'token' | 'isLoggedIn' | 'permissions'> & {
  wasMainOnboardingShown: boolean;
  wasMainWalkthroughShown: boolean;
};

const defaultStore: UserDefaultStore = {
  user: null,
  isLoggedIn: false,
  token: undefined,
  wasMainOnboardingShown: false,
  wasMainWalkthroughShown: false,
  permissions: [],
};

export const useUserStore = create<UserStore>()(
  devtools(
    persist(
      (set) => ({
        ...defaultStore,
        setUser: (user: UserType) => {
          set(
            produce((state: UserStore) => {
              state.user = user;
              state.isLoggedIn = true;
            })
          );
        },
        setIsLoggedIn: (isLoggedIn: boolean) => {
          set(
            produce((state: UserStore) => {
              state.isLoggedIn = isLoggedIn;
            })
          );
        },
        setToken: (token: string) => {
          set(
            produce((state: UserStore) => {
              state.token = token;
            })
          );
        },
        login: (user: UserType, token: string, permissions?: PermissionType[]) => {
          set(
            produce((state: UserStore) => {
              state.user = user;
              state.isLoggedIn = true;
              state.token = token;
              state.permissions = permissions ?? [];
            })
          );
        },
        logout: () => {
          set(
            produce((state: UserStore) => {
              state.user = null;
              state.isLoggedIn = false;
              state.token = undefined;
            })
          );
        },
        setWasMainOnboardingShown: () => {
          set(
            produce((state: UserStore) => {
              state.wasMainOnboardingShown = true;
            })
          );
        },
        setWasMainWalkthroughShown: () => {
          set(
            produce((state: UserStore) => {
              state.wasMainWalkthroughShown = true;
            })
          );
        },
        setPermissions: (permissions: PermissionType[]) => {
          set(
            produce((state: UserStore) => {
              state.permissions = permissions;
            })
          );
        }
      }),
      {
        name: 'user',
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
