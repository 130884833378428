import { type ClassValue, clsx } from 'clsx';
import { Dispatch, SetStateAction } from 'react';
import { twMerge } from 'tailwind-merge';
import { subDays } from 'date-fns';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const handlePaste = async (
  setValue: React.Dispatch<React.SetStateAction<string>>
) => {
  try {
    const text = await navigator.clipboard.readText();
    setValue(text);
  } catch (err) {
    console.error('Failed to read clipboard contents: ', err);
  }
};

export const convertDateToBackendFormat = (date?: Date|null) => {
  if (!date) return null;
  return date.toISOString().slice(0, 19).replace('T', ' ');
};

export const handleCheckboxChange =
  (setter: Dispatch<SetStateAction<boolean>>) => (checked: boolean) => {
    setter(checked);
  };

export const getWebsiteCode = (websiteAccessCode: string) => {
  return `
  <script>
    (function(n,t){if(!window.ConversionCraft){window.ConversionCraft={_p:[n,t]};
    var e=document.createElement("script");e.type="text/javascript";e.src="https://cdn.conversioncraft.ai/craft.js";
    e.async=!0;document.head.appendChild(e)}})
    ("${websiteAccessCode}",{});
  </script>`;
};

export const getDatesByDateRange = (dateRange: string) => {
  const now = new Date();
  let startDate: Date;

  switch (dateRange) {
    case 'today':
      startDate = now;
      break;
    case 'yesterday':
      startDate = subDays(now, 1);
      break;
    case 'last24hours':
      startDate = subDays(now, 1);
      break;
    case 'last7days':
      startDate = subDays(now, 7);
      break;
    case 'last14days':
      startDate = subDays(now, 14);
      break;
    case 'last30days':
      startDate = subDays(now, 30);
      break;
    case 'last90days':
      startDate = subDays(now, 90);
      break;
    case 'last180days':
      startDate = subDays(now, 180);
      break;
    case 'thismonth':
      startDate = new Date(now.getFullYear(), now.getMonth(), 1);
      break;
    case 'yeartodate':
      startDate = new Date(now.getFullYear(), 0, 1);
      break;
    case 'alltime':
      startDate = new Date(2000, 0, 1);
      break;
    default:
      startDate = subDays(now, 180);
  }

  const endDate = now;

  return { startDate, endDate };
};

export const parseDateGroupByToQuestDbSample = (groupBy: string) => {
  switch (groupBy) {
    case 'day':
      return '1d';
    case 'week':
      return '1w';
    case 'month':
      return '1M';
    case '5min':
      return '5m';
    case '1h':
      return '1h';
    default:
      return '1d';
  }
};

export const formatPercentage = (value: number, fixed = 2): string => {
  const formattedValue = value.toFixed(fixed);
  return `${formattedValue}%`;
};
