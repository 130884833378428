import { BlogPost, BlogPostBE } from '@/types/blog';

export const mapBlogPostBEsToDTO = (
  preRegBE: BlogPostBE
): BlogPost => {
  return {
    id: preRegBE.id.toString(),
  };
};

export const mapBlogPostDTOToBEForCreate = (
  blogPost: Partial<BlogPost>
): Partial<BlogPostBE> => {

  return {
  };
};

export const mapBlogPostsBEsToDTO = (
  blogPostBEArray: BlogPostBE[]
): BlogPost[] => {
  return blogPostBEArray.map((blogPostBE) => {
    return mapBlogPostBEsToDTO(blogPostBE);
  });
};
