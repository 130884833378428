import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { useTranslation } from '@/hooks/useTranslation';
import { useCallback, useEffect, useMemo } from 'react';
import { Button } from './ui/button';
import { useGetOrganizationList } from '@/hooks/organization/useGetOrganizationList';
import { useOrganizationStore } from '@/stores/organization';
import { LoadingIndicator } from './common/LoadingIndicator';
import { Organization } from '@/types/organization';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Building2 } from 'lucide-react';

export function OrganizationSelector() {
  const { t } = useTranslation('common');
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { data: organizations, isLoading } = useGetOrganizationList();
  const organizationsList = useMemo(() => organizations?.data || [], [organizations]);

  const setOrganization = useOrganizationStore(
    (state) => state.setOrganization
  );
  const organization = useOrganizationStore((state) => state.organization);

  const handleChange = useCallback(
    (organization: Organization) => {
      setOrganization(organization);

      // TODO: replace with react-router navigate when we clean the state handling
      window.location.replace('/');
    },
    [setOrganization]
  );

  useEffect(() => {
    setOrganization(organizationsList[0]);
  }, [organizationsList, setOrganization]);

  if (organizationsList.length <= 1) {
    return (
      <>
        {
          isMobile ? <></> : (
            <Button variant="outline">
              {organization?.name ?? t('noOrganizationIsSelected')}
            </Button>
          )
        }
      </>
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          {isMobile ? (
            <Building2 className="w-5 h-5" />
          ) : (
            organization?.name ?? t('noOrganizationIsSelected')
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>{t('organizations')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {isLoading && (
          <div>
            <LoadingIndicator size="small" />
          </div>
        )}
        {organizationsList.length === 0 ? (
          <DropdownMenuItem className="flex items-center justify-center p-4">
            <span>{t('noOrganizationIsAvailable')}</span>
          </DropdownMenuItem>
        ) : (
          organizationsList.map((org) => (
            <DropdownMenuItem
              key={org.id}
              className="flex items-start"
              onSelect={() => handleChange(org)}
            >
              <div className="flex flex-1 flex-col">
                <span>{org.name}</span>
              </div>
            </DropdownMenuItem>
          ))
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
