import { FC } from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from '@/components/ui/card';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { InfoIcon, ExternalLink } from 'lucide-react';

export const UNASGuide: FC = () => {
  const steps = [
    {
      title: 'Copy the ConversionCraft snippet',
      description:
        'Log in to your ConversionCraft account and find your tracking code snippet.',
    },
    {
      title: 'Access UNAS admin',
      description: 'Log in to your UNAS admin panel.',
    },
    {
      title: 'Navigate to HTML editor',
      description: 'Go to Appearance > HTML Editor.',
    },
    {
      title: 'Locate the appropriate file',
      description:
        'Find the main template file (often named index.html or similar).',
    },
    {
      title: 'Paste the snippet',
      description:
        'Paste the ConversionCraft snippet just before the closing </head> tag.',
    },
    {
      title: 'Save changes',
      description: "Click 'Save' to apply the changes to your UNAS theme.",
    },
  ];

  return (
    <Card className="w-full max-w-3xl mx-auto">
      <CardHeader>
        <CardTitle>How to Add ConversionCraft Snippet to UNAS</CardTitle>
        <CardDescription>
          Follow these steps to add your ConversionCraft tracking code to your
          UNAS store.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ol className="list-decimal list-inside space-y-4 mb-8">
          {steps.map((step, index) => (
            <li key={index}>
              <span className="font-bold">{step.title}</span>
              <p className="ml-5 text-sm text-gray-600">{step.description}</p>
              {index === 4 && (
                <Alert className="my-4 w-full max-w-2xl">
                  <InfoIcon className="h-4 w-4" />
                  <AlertTitle>Tip</AlertTitle>
                  <AlertDescription className="w-full max-w-2xl overflow-x-scroll">
                    Paste your snippet just before the closing &lt;/head&gt; tag
                    in the main template file:
                    <pre className="mt-2 bg-gray-100 p-2 rounded">
                      {`<head>\n  <!-- Other head content -->\n  <Paste your code here>\n</head>`}
                    </pre>
                  </AlertDescription>
                </Alert>
              )}
            </li>
          ))}
        </ol>
      </CardContent>
      <CardFooter>
        <a
          href="https://unas.hu/tudastar"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center text-blue-600 hover:underline"
        >
          Learn more about editing UNAS theme files
          <ExternalLink className="ml-1 h-4 w-4" />
        </a>
      </CardFooter>
    </Card>
  );
};
