import { useState } from 'react';
import { useTranslation } from '@/hooks/useTranslation';
import { Modal } from './Modal';
import { DialogFooter } from '../ui/dialog';
import { Button } from '../ui/button';
import { useModalContext } from '@/hooks/useModalContext';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { useWebsiteStore } from '@/stores/website';
import { Label } from '../ui/label';
import { WooCommerceGuide } from './providerGuides/WooCommerceGuide';
import { ShopifyGuide } from './providerGuides/ShopifyGuide';
import { UNASGuide } from './providerGuides/UNASGuide';
import { OwnSiteGuide } from './providerGuides/OwnSiteGuide';
import { getWebsiteCode } from '@/lib/utils';
import { toast } from 'sonner';
import { Copy } from 'lucide-react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion';
import { PrivacyPolicySnippetEN } from '../privacyPolicy/PrivacyPolicySnippetEN';
import { PrivacyPolicySnippetHU } from '../privacyPolicy/PrivacyPolicySnippetHU';

export const CopyCodeSnippet = () => {
  const { t, i18n } = useTranslation('common');
  const { closeModal } = useModalContext();
  const selectedAccessCode = useWebsiteStore(
    (state) => state.selectedAccessCode
  );
  const [selectedProvider, setSelectedProvider] = useState<
    string | undefined
  >();

  const handleClose = () => {
    closeModal('createNewWebsite');
    const setSelectedAccessCode =
      useWebsiteStore.getState().setSelectedAccessCode;
    setSelectedAccessCode(null);
  };

  const renderGuide = () => {
    switch (selectedProvider) {
      case 'shopify':
        return <ShopifyGuide />;
      case 'woocommerce':
        return <WooCommerceGuide />;
      case 'unas':
        return <UNASGuide />;
      case 'own':
        return <OwnSiteGuide />;
      case 'other':
        return null;
      default:
        return null;
    }
  };

  return (
    <Modal
      modalType="copyCodeSnippet"
      title={t('copyCodeSnippet')}
      onClose={handleClose}
    >
      {selectedAccessCode && (
        <div className="pb-8 flex items-center justify-center">
          <Button
            size="sm"
            variant="conversionCraft"
            className="gap-2"
            onClick={() => {
              const generatedCode = getWebsiteCode(selectedAccessCode);
              navigator.clipboard.writeText(generatedCode);
              toast.success(t('codeSnippetCopiedToClipboard'));
            }}
          >
            {t('copyCodeSnippetToClipboard')}
            <Copy className="cursor-pointer h-4 w-4 text-white" />
          </Button>
        </div>
      )}

      <div className="space-y-4 overflow-y-scroll px-1 mb-4">
        <Label className="text-lg">{t('important')}</Label>
        <p className="text-sm">{t('privacyPolicyImportantInfo')}</p>
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="privacyPolicySnippet" className="border-none">
            <AccordionTrigger>
              <div className="flex justify-between items-center text-sm">
                <span>{t('showPrivacyPolicySnippet')}</span>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              {i18n.language === 'en-US' ? (
                <PrivacyPolicySnippetEN />
              ) : (
                <PrivacyPolicySnippetHU />
              )}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="space-y-4 overflow-y-scroll px-1">
        <Label className="text-lg" htmlFor="provider">
          {t('selectYourWebsiteProvider')}
        </Label>
        <Select onValueChange={setSelectedProvider}>
          <SelectTrigger id="provider">
            <SelectValue placeholder={t('selectProvider')} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="shopify">Shopify</SelectItem>
            <SelectItem value="woocommerce">WooCommerce</SelectItem>
            <SelectItem value="unas">UNAS</SelectItem>
            <SelectItem value="own">{t('ownWebsite')}</SelectItem>
            <SelectItem value="other">{t('other')}</SelectItem>
          </SelectContent>
        </Select>

        {selectedProvider && <div className="mt-4">{renderGuide()}</div>}

        <DialogFooter className="mt-4">
          <Button variant="ghost" onClick={handleClose}>
            {t('close')}
          </Button>
        </DialogFooter>
      </div>
    </Modal>
  );
};
