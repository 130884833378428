import { useLocation } from 'react-router-dom'

export function useCheckActiveNav() {
  const { pathname } = useLocation()

  const checkActiveNav = (nav: string) => {
    // const pathArray = pathname.split('/').filter((item) => item !== '')

    // if (nav === '/' && pathArray.length < 1) return true
    // console.log(`nav: ${nav}, pathArray: ${pathArray}`, pathArray.includes(nav.replace(/^\//, '')), nav.replace(/^\//, ''))

    // return pathArray.includes(nav.replace(/^\//, ''))
    if (nav === '') {
      return false;
    }

    return pathname.includes(nav);
  }

  return { checkActiveNav }
}
