import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../hooks/useTranslation';
import { Button } from '../../ui/button';

interface ErrorDisplayProps {
  errorMessage?: string;
}

function ErrorDisplay({ errorMessage }: ErrorDisplayProps) {
  const { t } = useTranslation('common');

  const navigate = useNavigate();
  // const location = useLocation();

  // const from = location.state?.from?.pathname || '/';

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center gap-4">
      <h1 className="text-xl">{t('errorBoundaryTitle')}</h1>
      <h3>
        {t('errorBoundaryMessage', {
          message: errorMessage ?? '-',
        })}
      </h3>
      <Button onClick={() => navigate(0)}>
        {t('reload')}
      </Button>
    </div>
  );
}

export default ErrorDisplay;
