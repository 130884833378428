import { useTranslation } from '@/hooks/useTranslation';
import { FC } from 'react';
import { Button } from '../ui/button';
import { toast } from 'sonner';

export const PrivacyPolicySnippetHU: FC = () => {
  const { t } = useTranslation('common');

  const privacyPolicyText = `Adatgyűjtés harmadik felek által
Good Life Kft. és a Conversion Craft felugró ablakok

Weboldalunk a Good Life Kft. ("Good Life") által biztosított Conversion Craft felugró ablakokat használja a felhasználói élmény javítása és szükséges adatok gyűjtése érdekében. A Good Life adatgyűjtőként működik, és a személyes adatokat a nevünkben dolgozza fel. Az ezen felugró ablakokon keresztül gyűjtött adatok magukban foglalhatják, de nem korlátozódnak a következőkre:

- Kapcsolati információk (pl. e-mail címek, telefonszámok)
- Felhasználói preferenciák és visszajelzések
- Interakciós adatok (pl. hogyan lépnek kapcsolatba a felugró ablakokkal a felhasználók)

Az adatgyűjtés célja
A Good Life által a Conversion Craft segítségével gyűjtött adatokat az alábbi célokra használjuk fel:

- A felhasználói elkötelezettség és élmény növelése weboldalunkon
- Személyre szabott ajánlatok és promóciók biztosítása
- Felmérések készítése és visszajelzések gyűjtése szolgáltatásaink javítása érdekében
- Marketing és kommunikációs célok, amennyiben erre hozzájárulást kaptunk
- Gépi tanulási modellek képzése szolgáltatásaink és a felhasználói élmény javítása érdekében

Az adatkezelés jogalapja
A Good Life a következő jogalapok alapján dolgozza fel a személyes adatokat:

- Hozzájárulás: A Conversion Craft felugró ablakokkal való interakció és az arra adott kifejezett hozzájárulás révén a felhasználók beleegyeznek személyes adataik gyűjtésébe és feldolgozásába a leírt célokra, beleértve a gépi tanulási modellek képzését is.
- Jogos érdekek: Az adatgyűjtés szükséges szolgáltatásaink és a felhasználói elkötelezettség javítása érdekében, beleértve a gépi tanulási modellek képzését, feltéve, hogy ezen érdekeket nem írják felül az egyének jogai.

Adatmegosztás és továbbítás
A Good Life által a Conversion Craft segítségével gyűjtött adatokat az alábbiak számára oszthatjuk meg:

- Belső osztályaink számára, amelyek a marketingért, ügyfélszolgálatért és weboldal-kezelésért felelősek
- Harmadik fél szolgáltatók számára, akik segítenek az adatok feldolgozásában és elemzésében
- Gépi tanulási modellek fejlesztői számára, akik az adatokat algoritmusok képzésére és javítására használják szolgáltatásaink fejlesztése érdekében

Biztosítjuk, hogy minden harmadik fél megfelel a GDPR követelményeinek a személyes adatok védelme érdekében.

Adatmegőrzés
A Good Life által a Conversion Craft segítségével gyűjtött személyes adatokat csak addig őrizzük meg, ameddig szükséges az itt leírt célok teljesítéséhez, kivéve, ha a törvény hosszabb megőrzési időszakot ír elő vagy engedélyez.

Felhasználói jogok
A felhasználók a következő jogokkal rendelkeznek személyes adataik tekintetében:

- Hozzáférési jog: A felhasználók kérhetik a Good Life által a Conversion Craft segítségével gyűjtött személyes adataikhoz való hozzáférést.
- Helyesbítéshez való jog: A felhasználók kérhetik pontatlan vagy hiányos személyes adataik helyesbítését.
- Törléshez való jog: A felhasználók bizonyos feltételek mellett kérhetik személyes adataik törlését.
- Az adatkezelés korlátozásához való jog: A felhasználók kérhetik személyes adataik kezelésének korlátozását.
- Adathordozhatósághoz való jog: A felhasználók kérhetik személyes adataik struktúrált, általánosan használt és géppel olvasható formában történő megkapását.
- Tiltakozáshoz való jog: A felhasználók tiltakozhatnak személyes adataik bizonyos célokra történő feldolgozása ellen.

Ezeknek a jogoknak a gyakorlása érdekében a felhasználók kapcsolatba léphetnek velünk a [ügyfél kapcsolattartási információi] címen, vagy közvetlenül a Good Life-val:

Good Life Kft.
[Good Life Kft. Kapcsolattartási Információi]
[Good Life Kft. E-mail Címe]

Ezen rész módosításai
Időről időre frissíthetjük adatvédelmi nyilatkozatunk ezen részét, hogy tükrözze adatkezelési gyakorlataink vagy szabályozási követelmények változásait. Arra biztatjuk a felhasználókat, hogy időnként tekintsék át ezt a részt.`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(privacyPolicyText).then(
      () => {
        toast.success(
          t('privacyStatementHasBeenSuccessfullyCopiedToTheClipboard')
        );
      },
      (err) => {
        console.error('Failed to copy text: ', err);
        toast.error(t('anErrorOccurredWhileCopyingTheText'));
      }
    );
  };

  return (
    <div className="max-w-4xl mx-auto bg-white border rounded-lg p-6">
      <h1 className="text-xl font-bold mb-6 text-gray-800">
        Adatgyűjtés harmadik felek által
      </h1>
      <h2 className="text-lg font-semibold mb-4 text-gray-700">
        Good Life Kft. és a Conversion Craft felugró ablakok
      </h2>

      <p className="mb-4 text-gray-600">
        Weboldalunk a Good Life Kft. ("Good Life") által biztosított Conversion
        Craft felugró ablakokat használja a felhasználói élmény javítása és
        szükséges adatok gyűjtése érdekében. A Good Life adatgyűjtőként működik,
        és a személyes adatokat a nevünkben dolgozza fel. Az ezen felugró
        ablakokon keresztül gyűjtött adatok magukban foglalhatják, de nem
        korlátozódnak a következőkre:
      </p>

      <ul className="list-disc pl-6 mb-4 text-gray-600">
        <li>Kapcsolati információk (pl. e-mail címek, telefonszámok)</li>
        <li>Felhasználói preferenciák és visszajelzések</li>
        <li>
          Interakciós adatok (pl. hogyan lépnek kapcsolatba a felugró ablakokkal
          a felhasználók)
        </li>
      </ul>

      <h3 className="font-semibold mt-6 mb-3 text-gray-700">
        Az adatgyűjtés célja
      </h3>
      <p className="mb-2 text-gray-600">
        A Good Life által a Conversion Craft segítségével gyűjtött adatokat az
        alábbi célokra használjuk fel:
      </p>

      <ul className="list-disc pl-6 mb-4 text-gray-600">
        <li>A felhasználói elkötelezettség és élmény növelése weboldalunkon</li>
        <li>Személyre szabott ajánlatok és promóciók biztosítása</li>
        <li>
          Felmérések készítése és visszajelzések gyűjtése szolgáltatásaink
          javítása érdekében
        </li>
        <li>
          Marketing és kommunikációs célok, amennyiben erre hozzájárulást
          kaptunk
        </li>
        <li>
          Gépi tanulási modellek képzése szolgáltatásaink és a felhasználói
          élmény javítása érdekében
        </li>
      </ul>

      <h3 className="font-semibold mt-6 mb-3 text-gray-700">
        Adatmegosztás és továbbítás
      </h3>
      <p className="mb-2 text-gray-600">
        A Good Life által a Conversion Craft segítségével gyűjtött adatokat az
        alábbiak számára oszthatjuk meg:
      </p>

      <ul className="list-disc pl-6 mb-4 text-gray-600">
        <li>
          Belső osztályaink számára, amelyek a marketingért, ügyfélszolgálatért
          és weboldal-kezelésért felelősek
        </li>
        <li>
          Harmadik fél szolgáltatók számára, akik segítenek az adatok
          feldolgozásában és elemzésében
        </li>
        <li>
          Gépi tanulási modellek fejlesztői számára, akik az adatokat
          algoritmusok képzésére és javítására használják szolgáltatásaink
          fejlesztése érdekében
        </li>
      </ul>

      <p className="mb-4 text-gray-600">
        Biztosítjuk, hogy minden harmadik fél megfelel a GDPR követelményeinek a
        személyes adatok védelme érdekében.
      </p>

      <h3 className="font-semibold mt-6 mb-3 text-gray-700">Adatmegőrzés</h3>
      <p className="mb-4 text-gray-600">
        A Good Life által a Conversion Craft segítségével gyűjtött személyes
        adatokat csak addig őrizzük meg, ameddig szükséges az itt leírt célok
        teljesítéséhez, kivéve, ha a törvény hosszabb megőrzési időszakot ír elő
        vagy engedélyez.
      </p>

      <h3 className="font-semibold mt-6 mb-3 text-gray-700">
        Felhasználói jogok
      </h3>
      <p className="mb-2 text-gray-600">
        A felhasználók a következő jogokkal rendelkeznek személyes adataik
        tekintetében:
      </p>

      <ul className="list-disc pl-6 mb-4 text-gray-600">
        <li>
          Hozzáférési jog: A felhasználók kérhetik a Good Life által a
          Conversion Craft segítségével gyűjtött személyes adataikhoz való
          hozzáférést.
        </li>
        <li>
          Helyesbítéshez való jog: A felhasználók kérhetik pontatlan vagy
          hiányos személyes adataik helyesbítését.
        </li>
        <li>
          Törléshez való jog: A felhasználók bizonyos feltételek mellett
          kérhetik személyes adataik törlését.
        </li>
        <li>
          Az adatkezelés korlátozásához való jog: A felhasználók kérhetik
          személyes adataik kezelésének korlátozását.
        </li>
        <li>
          Adathordozhatósághoz való jog: A felhasználók kérhetik személyes
          adataik struktúrált, általánosan használt és géppel olvasható formában
          történő megkapását.
        </li>
        <li>
          Tiltakozáshoz való jog: A felhasználók tiltakozhatnak személyes
          adataik bizonyos célokra történő feldolgozása ellen.
        </li>
      </ul>

      <p className="mb-4 text-gray-600">
        Ezeknek a jogoknak a gyakorlása érdekében a felhasználók kapcsolatba
        léphetnek velünk a [ügyfél kapcsolattartási információi] címen, vagy
        közvetlenül a Good Life-val:
      </p>

      <address className="mb-4 text-gray-600">
        Good Life Kft.
        <br />
        [Good Life Kft. Kapcsolattartási Információi]
        <br />
        [Good Life Kft. E-mail Címe]
      </address>

      <h3 className="font-semibold mt-6 mb-3 text-gray-700">
        Ezen rész módosításai
      </h3>
      <p className="mb-4 text-gray-600">
        Időről időre frissíthetjük adatvédelmi nyilatkozatunk ezen részét, hogy
        tükrözze adatkezelési gyakorlataink vagy szabályozási követelmények
        változásait. Arra biztatjuk a felhasználókat, hogy időnként tekintsék át
        ezt a részt.
      </p>

      <div className="w-full flex items-center justify-center">
        <Button onClick={copyToClipboard} variant="conversionCraft" size="sm">
          {t('copyToClipboard')}
        </Button>
      </div>
    </div>
  );
};
