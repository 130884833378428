import { useTranslation } from '@/hooks/useTranslation';
import { Modal } from './Modal';
import { useEffect, useState } from 'react';
import { useModalContext } from '@/hooks/useModalContext';
import { WebsiteForm } from './forms/WebsiteForm';
import { Website } from '@/types/website';
import { useUpdateWebsite } from '@/hooks/website/useUpdateWebsite';
import { useWebsiteStore } from '@/stores/website';
import { toast } from 'sonner';

const DEFAULT_WEBSITE_DATA: Website = {
  id: '',
  name: '',
  url: '',
  created: '',
  updated: '',
  accessCodeId: '',
  status: 'missing_code',
  sitemapUrl: '',
  isUnas: false,
  unasApiKey: '',
  showPopupsOnAllPage: false,
  blockWebsite: false,
  blockedBySystem: false,
};

export const UpdateWebsiteModal = () => {
  const { t } = useTranslation('common');
  const { closeModal } = useModalContext();
  const selectedWebsite = useWebsiteStore((state) => state.selectedWebsite);
  const setSelectedWebsite = useWebsiteStore(
    (state) => state.setSelectedWebsite
  );

  const { mutate: updateWebsite } = useUpdateWebsite({
    id: selectedWebsite?.id || '',
    onSuccess: () => {
      toast.success(t('websiteUpdatedSuccessfully'));
      handleClose();
    },
    onError: () => {
      toast.error(t('failedToUpdateWebsite'));
    },
  });

  const [websiteData, setWebsiteData] = useState<Website>(
    selectedWebsite || DEFAULT_WEBSITE_DATA
  );

  useEffect(() => {
    setWebsiteData(selectedWebsite || DEFAULT_WEBSITE_DATA);
  }, [selectedWebsite]);

  const handleClose = () => {
    closeModal('updateWebsite');
    setSelectedWebsite(null);
  };

  const handleSave = () => {
    if (!websiteData?.id) return;

    updateWebsite({
      id: Number(websiteData.id),
      data: websiteData,
    });
  };

  return (
    <Modal modalType="updateWebsite" title={t('editWebsite')}>
      <div className="space-y-4">
        <WebsiteForm
          data={websiteData}
          onChange={setWebsiteData}
          onClose={handleClose}
          onSubmit={handleSave}
        />
      </div>
    </Modal>
  );
};
