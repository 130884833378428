import {
  InvalidateQueryFilters,
  QueryFilters,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { SendRequestResponse, useApi } from '@/hooks/useApi';
import { BLOG_POSTS } from '@/queryKeys';
import { endpoints } from '@/endpoints';
import { BlogPostBE } from '@/types/blog';
import { mapBlogPostDTOToBEForCreate } from '@/mappers/mapBlogPostDTO';

const BLOG_POSTS_QUERY_KEY = [BLOG_POSTS];

export const useCreateBlogPost = <BlogPost>({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();

  const { sendRequest } = useApi<BlogPostBE>({
    url: endpoints.blogPosts(),
    method: 'post',
  });

  return useMutation<
    SendRequestResponse<BlogPostBE>,
    Error,
    Partial<BlogPost>,
    { previousBlogPosts: BlogPostBE[] | undefined }
  >({
    mutationFn: async (data: Partial<BlogPost>) => {
      const mappedData = mapBlogPostDTOToBEForCreate(data);
      const response = await sendRequest(mappedData);

      if (response?.error) {
        throw new Error(response.error.message);
      }

      return response;
    },
    // With onMutate, we can update the cache before the mutation is executed
    onMutate: async (data: Partial<BlogPostBE>) => {
      await queryClient.cancelQueries(BLOG_POSTS_QUERY_KEY as QueryFilters);

      const previousBlogPosts = queryClient.getQueryData<BlogPostBE[]>([
        BLOG_POSTS,
      ]);

      queryClient.setQueryData<Partial<BlogPostBE>[]>([BLOG_POSTS], (old) => {
        if (old) {
          return [...old, data];
        }
        return [data];
      });

      return { previousBlogPosts };
    },
    onError: (_, __, context) => {
      console.log('ERROR');
      if (context?.previousBlogPosts) {
        queryClient.setQueryData([BLOG_POSTS], context.previousBlogPosts);
      }
      onError?.();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        BLOG_POSTS_QUERY_KEY as InvalidateQueryFilters
      );
      onSuccess?.();
    },
  });
};
