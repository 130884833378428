import { useQuery } from '@tanstack/react-query';
import { ListResponseType, ListResponseTypeDTO, useApi } from '@/hooks/useApi';
import { endpoints } from '@/endpoints';
import { ORGANIZATION } from '@/queryKeys';
import { Organization, OrganizationBE } from '@/types/organization';
import { mapListResponse } from '@/mappers/mapListResponse';
import { mapOrganizationsBEsToDTO } from '@/mappers/mapOrganizationDTO';

export const useGetOrganizationList = () => {
  const { sendRequest } = useApi<ListResponseType<OrganizationBE>>({
    url: endpoints.organizations,
    method: 'get',
  });

  return useQuery<ListResponseTypeDTO<Organization>, Error>({
    queryKey: [ORGANIZATION],
    queryFn: async () => {
      const response = await sendRequest();

      if (!response.data) {
        return {
          data: [],
          count: 0,
          perPage: 0,
          lastPage: 1,
          currentPage: 1,
          total: 1,
        };
      }

      return mapListResponse(response.data, mapOrganizationsBEsToDTO);
    },
  });
};
