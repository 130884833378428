import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import {
  LayoutDashboard,
  Globe,
  Flag,
  LayoutGrid,
  Users,
  ChartNoAxesCombined,
  // BookCopy,
} from 'lucide-react';

export type MenuItem = {
  icon: JSX.Element;
  label: string;
  href: string;
  active: boolean;
};

export type MenuStore = {
  items: MenuItem[];
  setActiveItem: (href: string) => void;
};

const isSidebarItemActive = (itemPath: string, currentPath: string) => {
  return currentPath.startsWith(itemPath);
};

export const useMenuStore = create<MenuStore>()(
  devtools((set) => ({
    items: [
      {
        icon: <LayoutDashboard className="h-5 w-5" />,
        label: 'dashboard',
        href: '/dashboard',
        active: false,
      },
      /* {
        icon: <BookCopy className="h-5 w-5" />,
        label: 'library',
        href: '/library',
        active: false,
      }, */
      {
        icon: <Globe className="h-5 w-5" />,
        label: 'websites',
        href: '/websites',
        active: false,
      },
      {
        icon: <Flag className="h-5 w-5" />,
        label: 'campaigns',
        href: '/campaigns',
        active: false,
      },
      {
        icon: <Users className="h-5 w-5" />,
        label: 'subscribers',
        href: '/subscribers',
        active: false,
      },
      {
        icon: <LayoutGrid className="h-5 w-5" />,
        label: 'popups',
        href: '/popups',
        active: false,
      },
      {
        icon: <ChartNoAxesCombined className="h-5 w-5" />,
        label: 'stats',
        href: '/statistics',
        active: false,
      },
    ],
    setActiveItem: (href: string) => {
      set(
        produce((state: MenuStore) => {
          state.items.forEach((item) => {
            item.active = isSidebarItemActive(item.href, href);
          });
        })
      );
    },
  }))
);
