import { baseURL } from './hooks/useApi';
// import querystring from 'node:querystring';

import {
  AUTH,
  BLOG_POSTS,
  CAMPAIGN,
  CAMPAIGNS,
  EDITOR,
  END,
  EXPORT,
  IFRAME,
  INVITATIONS,
  LOGIN,
  ME,
  ORGANIZATION,
  POPUP,
  // EDITOR,
  POPUPS,
  PRE_REGISTRATIONS,
  PROFILE,
  RESTART,
  RESUME,
  STATS,
  SUBSCRIBERS,
  SUSPEND,
  USER,
  USERS,
  WEBSITE_PAGE_TAGS,
  WEBSITES,
} from './queryKeys';

export type QueryParamValueTypes =
  | string
  | number
  | boolean
  | string[]
  | number[];

export type QueryParams = Record<string, QueryParamValueTypes> & {
  q?: string;
  _limit?: number;
  _sort?: string;
  _order?: string;
  _from?: string;
  _to?: string;
};

export const parseQueryParams = (params?: QueryParams) => {
  if (!params) return '';

  //   const query = querystring.stringify(params);

  //   return query ? `?${query}` : '';

  const query = new URLSearchParams(
    params as Record<string, string>
  ).toString();
  return query ? `?${query}` : '';
  //   const query = Object.keys(params)
  //     .map((key) => `${key}=${params[key]}`)
  //     .join('&');
  //   return query ? `?${query}` : '';
};

export const generateUrlWithParams = (
  postURL: string,
  params?: QueryParams
) => {
  const base = `${baseURL}/${postURL}`;
  if (!params) return base;

  const query = parseQueryParams(params);
  return `${base}${query}`;
};

export const endpoints = {
  // Campaigns
  campaigns: (params?: QueryParams) => generateUrlWithParams(CAMPAIGNS, params),
  campaign: (id: string) => `${baseURL}/${CAMPAIGNS}/${id}`,
  createCampaign: `${baseURL}/${CAMPAIGNS}`,
  updateCampaign: (id: string) => `${baseURL}/${CAMPAIGNS}/${id}`,
  deleteCampaign: (id: string) => `${baseURL}/${CAMPAIGNS}/${id}`,
  suspendCampaign: (id: string) => `${baseURL}/${CAMPAIGNS}/${id}/${SUSPEND}`,
  resumeCampaign: (id: string) => `${baseURL}/${CAMPAIGNS}/${id}/${RESUME}`,
  endCampaign: (id: string) => `${baseURL}/${CAMPAIGNS}/${id}/${END}`,
  restartCampaign: (id: string) => `${baseURL}/${CAMPAIGNS}/${id}/${RESTART}`,

  // Stats
  dashboardStats: (params?: QueryParams) =>
    generateUrlWithParams(STATS, params),
  campaignStats: (id: string) => `${baseURL}/${STATS}/${CAMPAIGN}/${id}`,
  campaignPopupStats: (id: string) => `${baseURL}/${STATS}/${POPUP}/${id}`,
  popupsStats: (params?: QueryParams) =>
    generateUrlWithParams(`${STATS}/popups`, params),

  // Subscribers
  subscribers: (params?: QueryParams) =>
    generateUrlWithParams(SUBSCRIBERS, params),
  subscriber: (id: string) => `${baseURL}/${SUBSCRIBERS}/${id}`,
  exportSubscribers: (params?: QueryParams) =>
    generateUrlWithParams(`${SUBSCRIBERS}/${EXPORT}`, params),

  // Pre Registrations
  preRegistrations: (params?: QueryParams) =>
    generateUrlWithParams(PRE_REGISTRATIONS, params),
  preRegistration: (id: string) => `${baseURL}/${PRE_REGISTRATIONS}/${id}`,
  preRegistrationComments: (id: string) => `${baseURL}/${PRE_REGISTRATIONS}/${id}/comments`,

  // Blog Posts
  blogPosts: (params?: QueryParams) =>
    generateUrlWithParams(BLOG_POSTS, params),

  // Websites
  websites: (params?: QueryParams) => generateUrlWithParams(WEBSITES, params),
  website: (id: string) => `${baseURL}/${WEBSITES}/${id}`,
  createWebsite: `${baseURL}/${WEBSITES}`,
  updateWebsite: (id: string) => `${baseURL}/${WEBSITES}/${id}`,
  deleteWebsite: (id: string) => `${baseURL}/${WEBSITES}/${id}`,
  websitePages: (id: string) => `${baseURL}/${WEBSITES}/${id}/pages`,
  websitePage: (id: string, pageId: string) =>
    `${baseURL}/${WEBSITES}/${id}/pages/${pageId}`,
  createWebsitePage: (id: string) => `${baseURL}/${WEBSITES}/${id}/pages`,
  updateWebsitePage: (id: string, pageId: string) =>
    `${baseURL}/${WEBSITES}/${id}/pages/${pageId}`,
  deleteWebsitePage: (id: string, pageId: string) =>
    `${baseURL}/${WEBSITES}/${id}/pages/${pageId}`,

  // Website tags
  websitePageTags: (params?: QueryParams) =>
    generateUrlWithParams(WEBSITE_PAGE_TAGS, params),

  // Popups
  popups: (params?: QueryParams) => generateUrlWithParams(POPUPS, params),
  popup: (id: string) => `${baseURL}/${POPUPS}/${id}`,
  createPopup: `${baseURL}/${POPUPS}`,
  updatePopup: (id: string) => `${baseURL}/${POPUPS}/${id}`,
  deletePopup: (id: string) => `${baseURL}/${POPUPS}/${id}`,

  // Campaign popups
  campaignPopups: (campaignId: string, params?: QueryParams) =>
    generateUrlWithParams(`${CAMPAIGNS}/${campaignId}/${POPUPS}`, params),
  campaignPopup: (campaignId: string, id: string) =>
    `${baseURL}/${CAMPAIGNS}/${campaignId}/${POPUPS}/${id}`,
  createCampaignPopup: (campaignId: string) =>
    `${baseURL}/${CAMPAIGNS}/${campaignId}/${POPUPS}`,
  updateCampaignPopup: (campaignId: string, id: string) =>
    `${baseURL}/${CAMPAIGNS}/${campaignId}/${POPUPS}/${id}`,
  deleteCampaignPopup: (campaignId: string, id: string) =>
    `${baseURL}/${CAMPAIGNS}/${campaignId}/${POPUPS}/${id}`,

  // Users
  users: `${baseURL}/${USERS}`,
  user: (id: string) => `${baseURL}/${USERS}/${id}`,
  createUser: `${baseURL}/${USERS}`,
  deleteUser: (id: string) => `${baseURL}/${USERS}/${id}`,
  updateUser: (id: string) => `${baseURL}/${USERS}/${id}`,

  // Invitations
  invitations: `${baseURL}/${INVITATIONS}`,
  createInvitation: `${baseURL}/${INVITATIONS}/`,
  updateInvitation: (id: string) => `${baseURL}/${INVITATIONS}/${id}`,
  deleteInvitation: (id: string) => `${baseURL}/${INVITATIONS}/${id}`,

  // Organization
  organizations: `${baseURL}/${ORGANIZATION}`,
  organization: (id: string) => `${baseURL}/${ORGANIZATION}/${id}`,

  // Profile
  profile: `${baseURL}/${USER}/${PROFILE}`,

  // login
  login: `${baseURL}/${AUTH}/${LOGIN}`,
  // Me
  me: `${baseURL}/${AUTH}/${ME}`,

  // Editor
  editor: `${baseURL}/${EDITOR}/${IFRAME}`,
};
