import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { produce } from 'immer';
import { Website } from '@/types/website';

export type WebsiteStore = {
  selectedWebsite: Website | null;
  setSelectedWebsite: (website: Website | null) => void;
  selectedAccessCode: string | null;
  setSelectedAccessCode: (accessCode: string | null) => void;
};

export const useWebsiteStore = create<WebsiteStore>()(
  devtools(
    persist(
      (set) => ({
        selectedWebsite: null,
        setSelectedWebsite: (website: Website | null) => {
          set(
            produce((state: WebsiteStore): void => {
              state.selectedWebsite = website;
            })
          );
        },
        selectedAccessCode: null,
        setSelectedAccessCode: (accessCode: string | null) => {
          set(
            produce((state: WebsiteStore): void => {
              state.selectedAccessCode = accessCode;
            })
          );
        },
      }),
      {
        name: 'website',
      }
    )
  )
);
